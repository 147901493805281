.NavbarIconView {
    width: 48px;
}

.navbar .title {
    text-shadow: 1px 1px 0 rgba(0,0,0,.23);
}

.navbar .link {
    text-shadow: 1px 1px 0 rgba(0,0,0,.23);
}

.navbar .user_profile_btn {
    width: 36px;
    background-repeat: no-repeat;
    background-position: center; 
    background-size: contain;
}

.navbar .right .button {
    margin-right:18px;
}

.navbar .open_sidebar_icon {
    display: none !important;
}
@media only screen and (max-width: 1339px) {
    
    .navbar .open_sidebar_icon {
        display: block !important;
    }
}

.event-page-subnavbar .subnavbar-inner {
    max-width: 920px !important;
    margin-left: auto;
    margin-right: auto;
}

.subnavbar-popover .item-media {
    min-width: 22px !important;
}