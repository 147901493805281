:root {
    --f7-theme-color: #13395B;
    --f7-theme-color-rgb: 43, 52, 82;
    --f7-theme-color-shade: #1E2439;
    --f7-theme-color-tint: #364369;

    --f7-bars-bg-color:  #ffffff;
    /*--f7-navbar-shadow-image: none;*/
    --f7-toolbar-bg-color: #ffffff;
    --f7-subnavbar-bg-color: #ffffff;
    --f7-bars-link-color: #000000;
    --f7-bars-text-color: #000000;

    --f7-safe-area-left: 0px;
    --f7-safe-area-right: 0px;
    --f7-safe-area-top: 0px;
    --f7-safe-area-bottom: 0px;
    --f7-safe-area-outer-left: 0px;
    --f7-safe-area-outer-right: 0px;
    --f7-device-pixel-ratio: 1;
    --f7-block-title-white-space: normal;
    --f7-page-bg-color:#f5f6fa;
    --f7-list-item-text-max-lines: 12;
    --f7-toggle-active-color:#2196f3;
    --f7-list-item-divider-bg-color:#dfe4ea;
    font-weight: normal;
  }

  @media only screen and (min-width: 1340px) {
    .hideLargeScreen {
      display: none !important;
    }
  }

  @media only screen and (max-width: 1339px) {
    .hideSmallScreen {
      display: none !important;
    }
  }

  @media only screen and (min-width: 768px) {
    .hideFullScreen{
      display: none !important;
    }
  }
  
  @media only screen and (max-width: 767px) {
    .hideMobileScreen {
      display: none !important;
    }
  }

.navbar-bg:before, .subnavbar:before {
  height: 2px;
}

.navbar .title {
  text-shadow: none;
}

.navbar .link {
  text-shadow: none;
}

.list textarea.resizable {
    height: 124px;
    min-height: 124px;
}

.text-editor-content {
  min-height: 240px;
}
.text-editor-content a {
  color: #2980b9;
}

.color-green {
  --f7-theme-color: #27ae60;
}

.row {
  justify-content: flex-start;
}

.label-cell {
  max-width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.popover-menu .item-media {
  min-width: 20px !important;
}

@media (min-width: 1024px) {
  .md .toast.toast-bottom, .md .toast.toast-top {
  left: 280px;
  }
}

.disabled .input-dropdown:before {
  display: none;
}

.signin-google-g-logo {
  background: url(../img/icons/Google_G_Logo.svg) no-repeat center;
  background-size: 70% auto;
}

.signin-facebook-f-logo {
  background: url(../img/icons/Facebook_F_icon.svg) no-repeat center;
  background-size: 70% auto;
}

.signin-linkedin-ln-logo {
  background: url(../img/icons/Linkedin_in_icon.svg) no-repeat center;
  background-size: 70% auto;
}

.card-visa-logo {
  background: url(../img/icons/visa-icon.svg) no-repeat center;
  background-size: 100% auto;
}

.card-mastercard-logo {
  background: url(../img/icons/mastercard-icon.svg) no-repeat center;
  background-size: 100% auto;
}

.card-amex-logo {
  background: url(../img/icons/amex-icon.svg) no-repeat center;
  background-size: 100% auto;
}



@media (min-width: 630px) {
  .popup {
    top: 0px;
    width: 100%;
    max-width: 630px;
    height: 100%;
    
    left: 50%;
    margin-left: calc(-1 * var(--f7-popup-tablet-width) / 2);
    transform: translate3d(0, 100vh, 0);
    box-shadow: var(--f7-popup-box-shadow);
    border-radius: var(--f7-popup-tablet-border-radius, var(--f7-popup-border-radius));
  }
}

.dialog-input-field{
  padding-bottom: 0 !important;
}

.filter-subnavbar .subnavbar-inner {
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
}

.events-searchbar .searchbar {
  border-radius: 20px;
  height: 35px;
  margin-left: 10px;
  margin-right: 10px;
}
/* Safari */
.events-searchbar .searchbar input[type='text'], .events-searchbar .searchbar input[type='search'] {
  -webkit-appearance: none;
  border-radius: 20px;
  height: 35px;
  padding-left: 52px;
}

.events-searchbar input {
  background-color: rgba(0,0,0,.06) !important;
}